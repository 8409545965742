import * as React from "react"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"

const RecentScholarlyImpactPage = () => (
  <Layout>
    <Seo title="Venture Capital" />
    <div id="content">
      <div id="feature_box"></div>
      <div class="post_box top" id="post-35">
        <div class="format_text">
          <h1>Venture Capital</h1>
          <p>
            <span
              style={{
                float: "left",
                margin: "0 0.5em 0.5em 0.5em",
                textAlign: "left",
                display: "block",
              }}
            >
              <StaticImage
                src="../images/shutterstock_10494934-Venture-Capital-300x200.jpeg"
                alt="Sprouting Plant"
              />
            </span>
            Credited with a dozen or so start-ups, Larry is well aware of the
            fragility of most small business.&nbsp; Although sometimes painful,
            risk taking, “struggling to make a payroll” and fighting the odds to
            create a successful business is fundamental to our economic way of
            life and key to job creation in a free society.&nbsp; From personal
            experience, he knows that every start-up provides unbelievable
            challenges for its entrepreneur(s) and remains very hard to achieve
            success.&nbsp; While working as an investment banker in New York at
            Donaldson, Lufkin &amp; Jenrette, he worked on several{" "}
            <a href="http://www.sproutgroup.com/">Sprout Group</a> transactions,
            DLJ’s institutional venture capital subsidiary.
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default RecentScholarlyImpactPage
